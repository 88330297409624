.spinner {
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-top: 4px solid rgb(55, 79, 237);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

.buttonSpinner {
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-top: 4px solid rgb(255, 255, 255);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

.fadeIn {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}